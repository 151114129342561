$block: 'contentBlock';

.#{$block} {
    margin: auto;
    text-align: left;
    line-height: 1.6;

    &__box-title {
        font-size: 18px;
        color: #345d60;
        margin-top: 10px;
    }

    &__button {
        float: right;
    }

    &__container {
        margin: 15px 0 25px 0;
        padding-right: 15px;
        padding-left: 15px;
        display: grid;
        grid-template-columns: 25% 75%;
    }

    &__content-box {
        text-align: left;
    }

    &__flex-wrap {
        display: grid;
        grid-template-columns: 50% 50%;
    }

    &__img {
        // align-self: baseline;
        // width: 25%;
        object-fit: contain;
        padding: 0 15px;
        justify-self: stretch;
        // padding-right: 15px;
        // padding-left: 15px;
    }

    &__title {
        border-bottom: solid 1px #ccc;
        padding-bottom: 15px;
        margin-bottom: 25px;
        font-size: 24px;
        color: #2e2e31;
        font-family: 'Roboto Slab', sans-serif;
        -webkit-font-smoothing: antialiased;
        font-weight: 500;
        line-height: 1.1;
    }

    &.three {
        .contentBlock__img {
            // height: 225px;
            width: 100%;
        }

        .contentBlock__flex-wrap {
            display: grid;
            grid-template-columns: 33% 33% 33%;
        }

        .contentBlock__container {
            display: flex;
            flex-direction: column;
        }

        .contentBlock__title {
            border-bottom: unset;
            margin-bottom: unset;
        }

        .contentBlock__content-box {
            display: flex;
            justify-content: center;
        }
    }

    @media only screen and (max-width: 480px) {
        &__flex-wrap {
            grid-template-columns: repeat(auto-fill, 100%) !important;

        }

        // .contentBlock.three {
        //     .contentBlock__flex-wrap {
        //         grid-template-columns: repeat(auto-fill, 100%);
        //     } 
        // }
    }
}
