$block: 'header';

.#{$block} {
    &__button {
        position: absolute;
        font-size: 18px;
        right: 20px;
        top: 30%;
    }

    &__hamburger {
        display: none;
    }

    &__nav-wrap {
        background: #87b0b3;
    }
    
    &__nav-bar {
        width: 100%;
        text-align: center;
        margin: 0;

        li {
            position: relative;
            color: #f9f9f9;
            font-size: 19px;
            line-height: 20px;
            padding: 15px 65px 15px 45px;            
            display: inline-block;
            margin: 5px;
            font-weight: 500;
            &:before {
                background: transparent url(../img/akc-humane-fund-image-sprites.png) 0 0 no-repeat;
                width: 22px;
                height: 26px;
                left: 18px;
                top: 9px;
                position: absolute;
                content: "";
            }

            &:hover, &.active {
                color: #000;

                &:before {
                    background: transparent url(../img/akc-humane-fund-image-sprites.png) 0 -28px no-repeat;
                }

                &.nav-1 {
                    &:before {
                        background: transparent url('../img/akc-humane-fund-image-sprites.png') -23px -28px no-repeat;
                    }    
                }

                &.nav-2 {
                    &:before {
                        background: transparent url('../img/akc-humane-fund-image-sprites.png') -52px -28px no-repeat;
                    }
                }    

                &.nav-3 {
                    &:before {
                        background: transparent url('../img/akc-humane-fund-image-sprites.png') -78px -28px no-repeat;
                    }
                }    
            }

            &.nav-1 {
                &:before {
                    background: transparent url('../img/akc-humane-fund-image-sprites.png') -23px 0 no-repeat;
                    top: 16px;
                    left: 14px;
                }
            }

            &.nav-2 {
                &:before {
                    background: transparent url('../img/akc-humane-fund-image-sprites.png') -52px 0 no-repeat;
                    left: 15px;
                    top: 13px;
                }
            }

            &.nav-3 {
                &:before {
                    background: transparent url('../img/akc-humane-fund-image-sprites.png') -78px 0 no-repeat;
                    left: 15px;
                    top: 13px;
                }
            }
        }

        a {
            cursor: pointer;
        }
    }

    &__hamburger {
        width: 40px;
        height: 40px;
    }

    &__wrap {
        position: relative;
        margin: auto;
        padding: 20px 0;
        text-align: center;
    }

    @media only screen and (min-width: 1100px) {
        &__wrap {
            width: 1070px;
        }
    }

    @media only screen and (max-width: 480px) {
        &__flex-wrap {
            grid-template-columns: repeat(auto-fill, 100%) !important;

        }

        &__button {
            width: 100px;
            margin: auto;
            position: unset;
        }

        &__container {
            background: #87b0b3;
            padding: 10px;
        }

        &__wrap {
            display: flex;
            flex-direction: column;
        }

        &__nav-bar {
            padding: 0 15px;
            li { 
                padding: 15px; 
                &:before {
                    background: unset !important;
                }
            }
        }

        &__nav-wrap {
            max-height: 0;
            transition: all .5s ease;
            visibility: hidden;
        }

        &__nav-wrap.open {
            max-height: 900px;
            visibility: initial;
        }

        &__hamburger {
            display: flex;
            width: 40px;
            height: 40px;
            margin-left: auto;
            padding: 10px;
            margin-right: 20px;
        }
    }
}
