.slick-dots {
	display: flex !important;
	justify-content: center;
	margin: 0;
	padding: 1rem 0;
    list-style-type: none;
    justify-content: center;
    position: absolute;
    bottom: 5px;
    left: 50%;
    z-index: 15;
    background: rgba(0,0,0, .7);
    padding: 15px 25px 10px 25px;
	
    li {
        margin: 0 0.25rem;
    }

    button {
        display: block;
        height: 15px;
        width: 15px;
        padding: 0;			
        border: none;
        border-radius: 100%;
        background-color: #3d3d42;
        text-indent: -9999px;
    }

    li.slick-active button {
        background-color: red;
    }
}