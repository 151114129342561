// @import 'bootstrap/dist/css/bootstrap.min.css';
/***
	6 == FORMS
***/
br {
    display: none;
}

form input,
form textarea {
    border: solid #87b0b3 2px;
    padding: 5px;
    border-radius: 10px;
    background: #e0e9e9;
}

.ff_composer {
    margin: 30px 0;
    width: 100%;
}

.ff_composer * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.ff_composer .line,
.ff_composer .line:last-child,
.ff_composer .last_unit {
    overflow: hidden;
}

.ff_composer .unit {
    float: left;
    padding: 10px;
}

.ff_composer .unit_right {
    float: right;
}

.ff_composer .size1of1 {
    float: none;
}

.ff_composer .size1of2 {
    width: 50%;
}

.ff_composer .size1of3 {
    width: 33.33333%;
}

.ff_composer .size2of3 {
    width: 66.66666%;
}

.ff_composer .size1of4 {
    width: 25%;
}

.ff_composer .size3of4 {
    width: 75%;
}

.ff_composer .line:last-child,
.ff_composer .last_unit {
    float: none;
    width: auto;
}

.ff_composer p,
.ff_composer h1,
.ff_composer h2,
.ff_composer h3,
.ff_composer h4,
.ff_composer h5,
.ff_composer h6 {
    margin-top: 0;
}

.ff_composer .required_item {
    margin-left: 4px;
    color: red;
}

.ff_composer textarea,
.ff_composer input[type="text"],
.ff_composer input[type="email"],
.ff_composer input[type="url"],
.ff_composer input[type="number"],
.ff_composer input[type="password"],
.ff_composer input[type="search"] {
    width: 100%;
}

.ff_composer ul.dynamic_recipients {
    list-style: none;
    padding: 0;
}

.ff_composer .field_label {
    font-weight: bold;
    display: block;
}

.ff_composer .error {
    display: block;
    color: #ff0000;
}

.ff_composer .line ul {
    list-style-type: none;

    li {
        label {
            padding-left: 5px;
        }
    }
}

$rescue: 'BreedRescueGrant';

.#{$rescue} {

    &__form__span {
        display: none;

    }

    &__form__span.active {
        display: block;

    }

    &__success {
        display: none;
    }

    &__success.active {
        display: block;
    }
}

$signUp: 'SignUp';

.#{$signUp} {

    &__success {
        display: none;
    }

    &__success.active {
        display: block;
    }

}

$makeCorp: 'MakeCorporateDonation';

.#{$makeCorp} {
    .line {
        ul {
            padding: 0;
        }
    }

    &__success {
        display: none;
    }

    &__success.active {
        display: block;
    }
}

$lifeInsurance: 'lifeInsuranceDonation';

.#{$lifeInsurance} {
    .line {
        ul {
            padding: 0;
        }
    }

    &__success {
        display: none;
    }

    &__success.active {
        display: block;
    }
}

$legacyGift: 'makeLegacyGift';

.#{$legacyGift} {
    .line {
        ul {
            padding: 0;
        }
    }

    &__success {
        display: none;
    }

    &__success.active {
        display: block;
    }
}

.field_label {
    &__required {
        padding-right: 20px;

        &:after {
            content: ' *';
            color: red;
            margin-right: -20px;
        }
    }
}

form {
    position: relative;
    border: solid lightgray 1px;
    margin-top: 20px;
    padding-left: 15px;
}

.error-message:after {
    content: ' This Field is required';
}

@keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.notification {
    animation: fadeout 3s
}

input[type=radio],
input.radio {
    float: left;
    clear: none;
    margin: 2px 10px 0 2px;
}

.form-modal {
    color: red;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    height: 200px;
    overflow-y: hidden;

    .modal-content {
        box-shadow: none;
        border: none;
        width: 80%;
        margin: 0px auto;
        text-align: center;
    }
}
