$block: 'footer';

.#{$block} {
    background: #87b0b3;
    font-size: 12px;
    padding-bottom: 50px;
    margin-top: 50px;

    &__block-container {
        position: relative;
        padding-right: 15px;
        padding-left: 15px;
        margin-left: 25%;
    }

    &_terms__container {
        background: none;
        text-align: center;
        padding-top: 15px;
    }

    &__container {
        // width: 1070px;
        margin: auto;
    }
    
    &__container.top {
        padding: 25px 0;
        background: #e0e9e9;
        margin-bottom: 50px;
        display: grid;
        grid-template-columns: 33% 33% 33%;

        h5 { 
            font-family: 'Cabin', sans-serif;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            font-size: 20px;
            color: #2f302c;
            text-align: left;
            margin: 10px 0;


            &:before {
                background: url(../img/akc-humane-fund-image-sprites.png) -23px 0 no-repeat;
                top: 14px;
                left: -15px;
                position: absolute;
                content: "";
                height: 18px;
                width: 21px;
            }
        }
    }

    &__block-container.block-0 {
        h5 {
            &:before {
                background-position: 2px -115px; 
            }
        }
        p {
            font-size: 16px;
            text-align: left;
        }
    }

    &__block-container.block-1 {
        h5 {
            &:before {
                background-position: -19px -115px;
                width: 18px !important;            
            }
        }
        .btn.submit {
            font-size: 11px;
            padding: 2px 6px;
            margin-left: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            margin-top: 0;
            border: 0;
            }
        }
        
        form p {
            display: flex;
            align-items: flex-start;
            margin: 0;        
    }    

    &__block-container.block-2 {
        h5 {
            &:before {
                background-position: -37px -115px;
            }
        }

        .footer__content {
            p {
                text-align: left;
                a {
                    text-align: left;
                    background: transparent url(../img/akc-humane-fund-image-sprites.png) -55px -115px no-repeat;
                    display: block;
                    width: 96px;
                    height: 20px;
                    text-indent: -999em;
                }
            }
        }
    }

    &__links {
        div {
            display: flex;
            justify-content: center;

            ul {
                list-style: none;
                width: 20%;
                li a {
                    color: #2f302c;
                    padding: 0px 4px;
                    text-decoration: none;
                    &:hover {
                        color: #2a6496;
                    }
                }
                li  {
                    &:first-child a {
                        color: white;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1100px) {
        &__container {
            width: 1070px;
        }
    }

    @media only screen and (max-width: 480px) {
        &__container.top {
            grid-template-columns: repeat(auto-fill, 100%);
            padding-left: 40px;
        }

        &__links {
            div {
                flex-direction: column;
                ul {
                    width: unset;
                }
            }
        }
        
        &__block-container {
            margin-left: unset;
        }
    }


}
