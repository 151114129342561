$block: 'flexibleContent';

.#{$block} {
    // width: 1070px;
    margin: auto;
    font-family: 'Cabin', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #61616a;
    text-align: left;
}
