$block: 'heroCarousel';

.#{$block} {
    // max-width: 720px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    // max-width: 600px;
    min-height: 279px;
    position: relative;
    width: 100%;
    max-width: 1440px;
    overflow: hidden;
    margin-bottom: 20px;

    &__container {
        position: relative;
    }

    &__content-box {
        position: absolute;
        max-width: 490px;
        bottom: 30%;
        text-shadow: 1px 1px 2px black;
        p {
            font-size: 19px;
        }
        h1 {
            font-size: 40px;
            font-family: 'Roboto Slab', sans-serif;
            font-weight: 300;
        }
        
        &.left {
            left: 12%;
        }
        &.right {
            right: 12%;
        }
        &.middle {
            right: 12%;
            left: 50%;
        }
        &.white {
            color: #fff;
        }
        &.block {
            color: #232326;
        }
    }

    &__img {
        width: 100%;
        max-height: 550px;
        display: block;
        margin: auto;
        object-fit: contain;
    }

    &__button {
        color: #fff;
        background: #dd4e58;
        border-radius: 8px;
        font-size: 18px;
        margin-top: 10px;
        display: inline-block;
        padding: 6px 16px;
        margin-bottom: 0;
        font-weight: bold;
        line-height: 1.428571429;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        text-decoration: none;
        background-image: none;
        text-shadow: none;
    }

    .slick-track {
        display: flex;
    }

    @media only screen and (max-width: 768px) {
        min-height: unset;

        &__content-box {
            &.right {
                left: 50%;
            }
        }

        &__img {
            width: unset;
        }

        &__content-box {
            width: 300px;
            left: 20% !important;
            bottom: 20%;

            h1 {
                font-size: 26px;
            }
        }
    }
}