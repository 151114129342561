@import './assets/stylesheets/test.scss';
@import './assets/stylesheets/header.scss';
@import './assets/stylesheets/footer.scss';
@import './assets/stylesheets/cookieBanner.scss';
@import './assets/stylesheets/heroCarousel.scss';
@import './assets/stylesheets/contentBlock.scss';
@import './assets/stylesheets/description.scss';
@import './assets/stylesheets/forms.scss';
@import './assets/stylesheets/slick.scss';
@import 'bootstrap/dist/css/bootstrap.min.css';

.App {
  overflow: hidden;
}

.dp-flex{
  display: flex;
}

.dp-none {
  display: none;
}

.btnn {
    color: #fff;
    background: #dd4e58;
    border-radius: 8px;
    font-size: 14px;
    margin-top: 10px;
    display: inline-block;
    padding: 6px 16px;
    margin-bottom: 0;
    font-weight: bold;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    text-decoration: none;
    background-image: none;
    text-shadow: none;
    &:hover {
      text-decoration: none;
    }
}

.btn {
  background: #dd4e58;
  color: #fff;
  font-weight: 700;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


 .jumbotron h1 { font-size: 25px; color: #61616a; }
 .jumbotron p { color: #61616a; font-size: 16px; line-height: 1.8; }
 .jumbotron .img-featured { margin: 0 35px 0 20px; }
 .jumbotron .row.key-points > div { min-height: 135px; text-align: center; border-right: solid 1px #c7c7cb; }
 .key-points p { line-height: 1.4; margin-top: 10px;}
 .glyphicons { padding: 0; } 
 .glyphicons:before { color: #87b0b2; font-size: 45px; position: relative; /*margin: 0 auto; margin-bottom: 5px;*/ }
